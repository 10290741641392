import React from "react"
import "../styles/styles.scss"
import ALSPageComponent from "../components/alsPageComponent"
import { Helmet } from "react-helmet"

const LSPage = () => (
  <div>
  <Helmet
    title="Security-Alarm-KMU" defer={false}>
      <html lang="de" />
      <meta name="description" content="ALARM-Projekt -- Analoge Lernszenarien" />
      <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU" />
  </Helmet>
    <ALSPageComponent
      ls="2" 
      game="als2"
      motto="Multi-Faktor-Authentifizierung"
      moderation="Als02OModeration"
      handout="Als02OHandout"
      konstruktion="Als02OKonstruktion"
      vorlage="Als02ODruckvorlagen"/>
  </div>
)

export default LSPage
